<template>
  <div class="idea">
    <Card2 inner-gradient="true">
      <div
        class="level-card"
        :style="{ 'background-image': 'url(' + iconSrc + ')' }"
      >
        <h4 class="heading">
          <span class="sub-heading" :style="{ color: idea.category.color }">{{
            idea.category.name
          }}</span>
          {{ idea.name }}
        </h4>
      </div>
    </Card2>
    <div class="footer">
      <div class="votes">
        <i class="heart fa-heart" :class="heartIcon" />
        {{ idea.votes }} votes
      </div>
      <div class="user">by {{ idea.user }}</div>
    </div>
  </div>
</template>

<script>
import Card2 from '@/components/global/ui/Card2.vue'
export default {
  name: 'LevelCard',
  components: {
    Card2
  },
  props: {
    idea: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    heartIcon() {
      return this.idea.voted ? 'fa' : 'fal'
    },
    iconSrc() {
      switch (this.idea.category.icon) {
        case 'recycle':
          return require('@/assets/images/ideas/waste-icon.svg')
        case 'biodiversity':
          return require('@/assets/images/ideas/biodiversity-icon.svg')
        case 'food':
          return require('@/assets/images/ideas/food-icon.svg')
        case 'energy':
          return require('@/assets/images/ideas/energy-icon.svg')
        default:
          return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.level-card {
  display: flex;
  height: 170px;
  background-position: 85% 15%;
  background-size: 100px 100px;
  background-repeat: no-repeat;
  flex-direction: column-reverse;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  background-color: #f7f8f3;

  &::after {
    opacity: 0.5;
    display: block;
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    height: 50%;
    z-index: 1;
    background: linear-gradient(
      0deg,
      rgba(#b9bda8, 1) 0%,
      rgba(#b9bda8, 0) 100%
    );
  }
}

.heading {
  font-size: 2.4rem;
  line-height: 2.8rem;
  font-weight: 400;
  margin: 0;
  color: #4a4a4a;
  padding: 16px;
  position: relative;
  z-index: 2;

  .sub-heading {
    display: block;
    font-size: 1.6rem;
    font-weight: 600;
  }
}

.idea /deep/ .card {
  margin-bottom: 9px;
}

.footer {
  display: flex;
  padding: 0 12px;
  justify-content: space-between;
  margin-bottom: 16px;
}

.votes {
  font-size: 16px;
  color: #545454;
  font-weight: 400;
  display: flex;
  align-items: center;

  .heart {
    font-size: 20px;
    margin-right: 7px;
    color: #f94079;
  }
}

.footer {
  font-size: 14px;
  font-style: italic;
  color: #aeaeae;
}
</style>
