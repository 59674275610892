<template>
  <div class="page">
    <div class="page-header-with-title-no-image">
      <h1>
        Ideas wall
      </h1>
    </div>
    <Content class="ideas__content">
      <div class="ideas__wrapper">
        <IdeasCard v-for="idea in ideas" :key="idea.id" :idea="idea" />
      </div>
    </Content>
  </div>
</template>

<script>
import IdeasCard from '@/components/global/ui/cards/IdeasCard.vue'

const CATEGORY_MAP = {
  recycle: {
    name: 'Waste & recycling',
    icon: 'recycle',
    color: '#8FC476'
  },
  food: {
    name: 'Food',
    icon: 'food',
    color: '#D33C45'
  },
  energy: {
    name: 'Energy',
    icon: 'energy',
    color: '#FEBA0C'
  },
  biodiversity: {
    name: 'Biodiversity',
    icon: 'biodiversity',
    color: '#D783B5'
  }
}

export default {
  components: {
    IdeasCard
  },
  data() {
    return {
      ideas: [
        {
          id: 1,
          name: 'Ditch plastic bottles',
          category: CATEGORY_MAP.recycle,
          user: 'Stephen Clark',
          votes: 206,
          voted: true
        },
        {
          id: 2,
          name: 'Food waste challenge',
          category: CATEGORY_MAP.food,
          user: 'Clare Smith',
          votes: 188,
          voted: false
        },
        {
          id: 3,
          name: 'Digital and online impact challenge?',
          category: CATEGORY_MAP.energy,
          user: 'David Jones',
          votes: 146,
          voted: true
        },
        {
          id: 4,
          name: 'Plastic free week',
          category: CATEGORY_MAP.recycle,
          user: 'Nadia Edwards',
          votes: 121,
          voted: true
        },
        {
          id: 5,
          name: 'Community garden',
          category: CATEGORY_MAP.biodiversity,
          user: 'Charlotte Michaels',
          votes: 92
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/components/page-header';

.ideas__content {
  @include respond-above(sm) {
    width: 100%;
    padding: 2.4rem 15vw !important;
  }
}

.ideas__wrapper {
  @include respond-above(md) {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    /deep/ .idea {
      flex-basis: calc(50% - 16px);

      &:nth-child(odd) {
        margin-right: 8px;
      }

      &:nth-child(even) {
        margin-left: 8px;
      }
    }
  }
}
</style>
